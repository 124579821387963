import { render, staticRenderFns } from "./Container.vue?vue&type=template&id=2335a980&scoped=true"
import script from "./Container.vue?vue&type=script&lang=js"
export * from "./Container.vue?vue&type=script&lang=js"
import style0 from "./Container.vue?vue&type=style&index=0&id=2335a980&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2335a980",
  null
  
)

export default component.exports