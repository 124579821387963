<template>
  <div class="main-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SelfSignUpContainer'
}
</script>

<style scoped>
.main-container {
  width: 100%;
}
</style>
