<script>
import GondolaItem from '@/components/bag/GondolaItem.vue'
import BagWithContents from '@/components/bag/BagWithContents.vue'
import GondolaIcon from '@/components/bag/GondolaIcon.vue'
import { bagModelType, partnerConfigurationModelType } from '@/util/constants'
import { mapGetters } from 'vuex/dist/vuex.common.js'

export default {
  name: 'GondolaCard',
  components: {
    GondolaItem: GondolaItem,
    BagWithContents: BagWithContents,
    GondolaIcon
  },
  props: {
    gondolaType: String,
    enabled_configurations: [],
    initialGondolas: [],
    gondolaKey: String
  },
  data() {
    const customPriceGondolaIsEnabled =
      this.enabled_configurations.find(
        (feature) =>
          feature === partnerConfigurationModelType.BAG_DYNAMIC_PRICE_MODEL
      ) !== undefined

    const contentGondolaIsEnabled =
      this.enabled_configurations.find(
        (feature) =>
          feature === partnerConfigurationModelType.BAG_WITH_CONTENT_MODEL
      ) !== undefined

    return {
      gondolas: [],
      customPriceGondolaIsEnabled,
      contentGondolaIsEnabled,
      buttonIsActive: false,
      bagsToAdd: [],
      dynamicPriceBagsToAdd: [],
      contentBagsToAdd: [],
      screenSize: this.$vuetify.breakpoint.name,
      showDialogContentCreatedBag: false,
      errors: [],
      btnLoading: false
    }
  },
  methods: {
    updateGondolas() {
      setTimeout(() => {
        this.$emit('updateGondolaMap')
      }, 500)
    },
    getGondolaErrors(gondolaKey) {
      const errorsMap = this.$store.getters.getGondolaBagErrors
      if (errorsMap.has(gondolaKey)) {
        return errorsMap.get(gondolaKey)
      } else {
        return []
      }
    },
    addGondolaBagError(gondolaKey, error) {
      const errorsMap = this.$store.getters.getGondolaBagErrors

      if (errorsMap.has(gondolaKey)) {
        const array = errorsMap.get(gondolaKey)
        array.push(error)
      } else {
        errorsMap.set(gondolaKey, [error])
      }
      this.$store.commit('setGondolaBagErrors', errorsMap)
      this.errors = this.getGondolaErrors(this.gondolaKey)
      setTimeout(() => {
        this.cleanErrors()
      }, 5000)
    },
    cleanErrors() {
      this.errors = []
      this.$store.commit('cleanGondolaBagErrors')
    },
    getPartner() {
      return this.$store.getters.getEstabelecimento
    },
    getPartnerId() {
      return this.$store.getters.getEstabelecimento.id
    },
    getField(obj, path) {
      const keys = path.split('.')
      let result = obj
      for (const key of keys) {
        result = result[key]
        if (result === undefined) {
          return null
        }
      }
      return result
    },
    compareFields(field1, field2) {
      return field1 && field2 && field1 === field2
    },
    compareGondolaByValue(gondola, gondolaToCompare) {
      const id = this.getField(gondola, 'cesta.id')
      const bagType = this.getField(gondola, 'cesta.tipo')
      const category = this.getField(gondola, 'cesta.category')
      const referencePrice = this.getField(gondola, 'cesta.valor_referencia')
      const tag = this.getField(gondola, 'cesta.tag')

      const idToCompare = this.getField(gondolaToCompare, 'cesta.id')
      const bagTypeToCompare = this.getField(gondolaToCompare, 'cesta.tipo')
      const categoryToCompare = this.getField(
        gondolaToCompare,
        'cesta.category'
      )
      const referencePriceToCompare = this.getField(
        gondolaToCompare,
        'cesta.valor_referencia'
      )
      const tagToCompare = this.getField(gondolaToCompare, 'cesta.tag')

      const hasTag = tag !== null && tagToCompare !== null
      if (hasTag) return this.compareFields(tag, tagToCompare)

      return (
        this.compareFields(id, idToCompare) ||
        (gondola.customPriceBag && gondolaToCompare.customPriceBag) ||
        (this.compareFields(bagType, bagTypeToCompare) &&
          this.compareFields(category, categoryToCompare) &&
          this.compareFields(referencePrice, referencePriceToCompare))
      )
    },

    handleInputChange(event) {
      const changedGondolaIndex = this.gondolas.findIndex((gondola) =>
        this.compareGondolaByValue(gondola, event.gondola)
      )

      if (changedGondolaIndex !== -1) {
        const changedGondola = this.gondolas[changedGondolaIndex]

        switch (event.eventType) {
          case 'default':
            this.handleDefaultBag(event, changedGondola)
            break
          case 'custom':
            this.handleCustomPriceBag(event, changedGondola)
            break
          case 'content':
            this.handleContentBag(event, changedGondola)
          default:
            break
        }
      }
    },

    handleDefaultBag(event, changedGondola) {
      const bagToAddIndex = this.bagsToAdd.findIndex((gondola) =>
        this.compareGondolaByValue(gondola, event.gondola)
      )
      const bagToAdd = {
        quantidade: event.quantidade,
        change_intention_count: event.change_intention_count,
        cesta: {
          id: event.id,
          price: event.price,
          price_reference: event.price_reference
        },
        gondola: event.gondola
      }

      if (bagToAddIndex !== -1) {
        if (changedGondola.quantidade === event.quantidade) {
          this.bagsToAdd.splice(bagToAddIndex, 1)
        } else {
          this.bagsToAdd[bagToAddIndex].quantidade = event.quantidade
          this.bagsToAdd[bagToAddIndex].change_intention_count =
            event.change_intention_count
        }
      } else {
        this.bagsToAdd.push(bagToAdd)
      }
    },

    handleCustomPriceBag(event, changedGondola) {
      if (event.quantidade == null) return
      const refPrice = event.customReferencePrice ?? event.price_reference
      if (!refPrice) return

      event.gondola.price_reference = refPrice
      const customBagToAdd = {
        quantidade: event.quantidade,
        price_reference: refPrice,
        price: event.customPrice ?? null,
        change_intention_count: event.change_intention_count,
        gondola: {
          ...event.gondola,
          quantidade: event.quantidade,
          cesta: {
            id: null,
            tipo: event.gondola.cesta?.tipo,
            category: event.gondola.cesta?.category,
            description: null,
            content: null,
            valor_referencia: Number(refPrice ?? 0)
          }
        }
      }

      this.dynamicPriceBagsToAdd = [customBagToAdd]
    },

    handleContentBag(event, changedGondola) {
      const contentBag = {
        quantidade: event.quantidade,
        change_intention_count: event.change_intention_count,
        cesta: {
          id: event.id,
          price: event.price,
          preco_referencia: event.price_reference //verificar se necessário
        },
        gondola: event.gondola
      }

      const indexContent = this.contentBagsToAdd.findIndex((bag) =>
        this.compareGondolaByValue(bag.gondola, event.gondola)
      )

      if (indexContent !== -1) {
        if (changedGondola.quantidade === event.quantidade) {
          this.contentBagsToAdd.splice(indexContent, 1)
        } else {
          this.contentBagsToAdd.splice(indexContent, 1, contentBag)
        }
      } else {
        this.contentBagsToAdd.push(contentBag)
      }
    },

    generateKeyToGondolaItem(item, index) {
      const itemId = item?.id || (item?.cesta?.id ? item.cesta.id : 'gondola')
      const itemTag = item?.cesta?.tag ? `-${item.cesta.tag}` : ''
      return `${itemId}${itemTag}-${index}`
    },

    changeBagQuantityOrDelete(bag) {
      if (!bag.gondola?.id) {
        this.$http
          .post(`/v2/partners/${this.getPartnerId()}/gondolas`, {
            quantity: bag.change_intention_count,
            bag: { id: bag.cesta.id }
          })
          .then(() => this.updateGondolas())
          .catch((error) => this.handleErrorOnChangeBag(error))
      } else {
        this.$http
          .post(
            `/v1/partners/${this.getPartnerId()}/gondolas/${bag.gondola?.id}`,
            {
              quantity: bag.change_intention_count
            }
          )
          .then(() => this.updateGondolas())
          .catch((error) => this.handleErrorOnChangeBag(error, bag))
      }

      const eventProperties = {
        bag_type: bag.cesta.type,
        bag_category: bag.category,
        bag_qty: bag.change_intention_count,
        bag_value: bag.cesta.price,
        bag_description: bag.gondola?.cesta?.description
      }

      this.$gtag.event('addbag_to_sell', eventProperties)
    },

    handleErrorOnChangeBag(error, bag) {
      const { data } = error.response
      this.addGondolaBagError(this.gondolaKey, data)

      const currentGondolaIndex = this.gondolas.findIndex((gondola) =>
        this.compareGondolaByValue(gondola, bag.gondola)
      )

      if (currentGondolaIndex !== -1) {
        const currentGondola = this.gondolas[currentGondolaIndex]
        this.$set(
          this.gondolas,
          currentGondolaIndex,
          Object.assign({}, currentGondola)
        )
      }
    },

    handleErrorOnChangeCustomPriceBag(error, bag) {
      const { data } = error.response
      this.addGondolaBagError(this.gondolaKey, data)

      const currentGondolaIndex = this.gondolas.findIndex((gondola) =>
        this.compareGondolaByValue(gondola, bag.gondola)
      )

      if (currentGondolaIndex !== -1) {
        const currentGondola = this.gondolas[currentGondolaIndex]

        this.$set(
          this.gondolas,
          currentGondolaIndex,
          Object.assign({}, currentGondola)
        )
      }
    },

    handleErrorOnChangeContentBag(error, contentBag) {
      this.errors.push(error.response.data)

      setTimeout(() => {
        this.cleanErrors()
      }, 5000)

      const currentGondolaIndex = this.gondolas.findIndex((gondola) =>
        this.compareGondolaByValue(gondola, contentBag.gondola)
      )
      if (currentGondolaIndex != -1) {
        const currentGondola = this.gondolas[currentGondolaIndex]
        // Atualiza referência do objeto pra forçar o update no componente filho e manter ele atualizado
        this.$set(
          this.gondolas,
          currentGondolaIndex,
          Object.assign({}, currentGondola)
        )
      }
    },

    updateCustomPriceGondolaData(response, bag) {
      this.updateGondolas()
    },

    updateContentBagGondolaData(response, contentBag) {
      this.updateGondolas()
    },

    createCustomPriceGondola(bag) {
      this.$http
        .post(`/v2/partners/${this.getPartnerId()}/gondolas`, {
          quantity: bag.change_intention_count,
          gondola_bag_id: null,
          bag: {
            id: null,
            partner_id: this.getPartnerId(),
            category: bag.gondola?.cesta?.category,
            type: bag.gondola?.cesta?.tipo,
            reference_price: bag.price_reference,
            price: bag.price,
            content: bag.gondola?.cesta?.content,
            description: bag.gondola?.cesta?.description
          }
        })
        .then((gondolasResponse) =>
          this.updateCustomPriceGondolaData(gondolasResponse, bag)
        )
        .catch((error) => this.handleErrorOnChangeCustomPriceBag(error, bag))

      const eventProperties = {
        bag_type: bag.gondola?.cesta?.tipo,
        bag_category: bag.gondola?.cesta?.category,
        bag_qty: bag.change_intention_count,
        bag_value: bag.gondola?.cesta?.valor_referencia,
        bag_description: bag.gondola?.cesta?.description
      }

      this.$gtag.event('addbag_to_sell', eventProperties)
    },

    createContentBagGondola() {
      this.showDialogContentCreatedBag = true
    },

    changeCustomPriceBagQuantityOrDelete(bag) {
      if (!bag.gondola?.id) {
        this.createCustomPriceGondola(bag)
      } else {
        this.updateCustomPriceGondola(bag)
      }
    },

    updateCustomPriceGondola(bag) {
      this.$http
        .post(
          `/v1/partners/${this.getPartnerId()}/gondolas/${
            bag.gondola?.id ?? currentGondola.id
          }`,
          {
            quantity: bag.change_intention_count
          }
        )
        .then((gondolasResponse) =>
          this.updateCustomPriceGondolaData(gondolasResponse, bag)
        )
        .catch((error) => this.handleErrorOnChangeCustomPriceBag(error, bag))
    },

    changeContentBagQuantityOrDelete(contentBag) {
      this.$http
        .put(
          `/v1/partners/${this.getPartnerId()}/content-gondolas/${
            contentBag.gondola.id
          }/${contentBag.gondola.cesta.gondola_bag_id}`,
          {
            quantity: contentBag.change_intention_count
          }
        )
        .then((contentBagResponse) =>
          this.updateContentBagGondolaData(contentBagResponse, contentBag)
        )
        .catch((error) => this.handleErrorOnChangeContentBag(error, contentBag))
    },

    recreateGondolaFromContentBag(contentBag) {
      const requestData = {
        quantity: contentBag.change_intention_count,
        bag: {
          id: contentBag.gondola.cesta.id,
          partner_id: contentBag.gondola.cesta.partner_id,
          category: contentBag.gondola.cesta.category,
          type: contentBag.gondola.cesta.tipo,
          reference_price: contentBag.gondola.cesta.valor_referencia,
          price: null,
          content: contentBag.gondola.cesta.content,
          description: contentBag.gondola.cesta.description
        },
        gondola_bag_id: contentBag.gondola.cesta.gondola_bag_id
      }

      this.$http
        .post(`/v2/partners/${this.getPartnerId()}/gondolas`, requestData)
        .then((response) =>
          this.updateContentBagGondolaData(response, contentBag)
        )
        .catch((error) => this.handleErrorOnChangeContentBag(error, contentBag))
    },

    updateGondolaCard(bag) {
      this.showDialogContentCreatedBag = false
      this.updateGondolas()
    },

    addNewBags() {
      this.btnLoading = true
      Promise.allSettled([
        ...this.bagsToAdd.map(async (bagToAdd) => {
          this.changeBagQuantityOrDelete(bagToAdd)
        }),
        ...this.dynamicPriceBagsToAdd.map(async (dynamicPriceBagToAdd) => {
          this.changeCustomPriceBagQuantityOrDelete(dynamicPriceBagToAdd)
        }),
        ...this.contentBagsToAdd.map(async (contentBagToAdd) => {
          if (contentBagToAdd.gondola.id) {
            this.changeContentBagQuantityOrDelete(contentBagToAdd)
          } else {
            this.recreateGondolaFromContentBag(contentBagToAdd)
          }
        })
      ])
        .then(() => {
          this.bagsToAdd = []
          this.dynamicPriceBagsToAdd = []
          this.contentBagsToAdd = []
          this.$refs.gondolaItems?.forEach((item) => item.resetCustomPrices())
        })
        .finally(() => {
          this.btnLoading = false
          this.buttonIsActive = false
        })
    },

    isGondolaButtonEnabled() {
      if (this.estabelecimentoTemCadastroPendente) {
        return true
      }
      if (this.contentGondolaIsEnabled) return false
      if (!this.buttonIsActive && !this.buttonIsActiveCustom) return true
    },

    closeProductsDialog() {
      this.$refs.bagWithContents.cleanPage()
      this.showDialogContentCreatedBag = false
    }
  },

  computed: {
    getSum() {
      return this.gondolas.reduce((acc, gondola) => {
        return acc + gondola.quantidade
      }, 0)
    },
    isUpdateButtonEnabled() {
      const defaultBagsHasQuantity = this.bagsToAdd.some(
        (bag) => bag.change_intention_count !== 0
      )
      const dynamicPriceBagsHasQuantity = this.dynamicPriceBagsToAdd.some(
        (bag) => bag.change_intention_count !== 0
      )
      const contentBagsHasQuantity = this.contentBagsToAdd.some(
        (bag) => bag.change_intention_count !== 0
      )

      return Boolean(
        defaultBagsHasQuantity ||
          dynamicPriceBagsHasQuantity ||
          contentBagsHasQuantity
      )
    },
    ...mapGetters(['estabelecimentoTemCadastroPendente'])
  },

  watch: {
    initialGondolas: {
      handler(updatedGondolas) {
        if (this.customPriceGondolaIsEnabled) {
          this.gondolas = updatedGondolas.filter((gondola) => !gondola.cesta.id)
        } else if (this.contentGondolaIsEnabled) {
          this.gondolas = updatedGondolas.filter((gondola) => gondola.cesta.tag)
        } else {
          this.gondolas = updatedGondolas
        }
        this.btnLoading = false
      }
    }
  },

  mounted() {
    if (this.customPriceGondolaIsEnabled) {
      this.gondolas = this.initialGondolas.filter(
        (gondola) => !gondola.cesta.id
      )
    } else if (this.contentGondolaIsEnabled) {
      this.gondolas = this.initialGondolas.filter(
        (gondola) => gondola.cesta.tag
      )
    } else {
      this.gondolas = this.initialGondolas
    }

    this.errors = this.getGondolaErrors(this.gondolaKey)
    setTimeout(() => {
      this.cleanErrors()
    }, 5000)
  }
}
</script>

<template>
  <v-card
    class="rounded-xl pa-2 ma-1 gondola-card"
    style="display: flex; flex-direction: column"
  >
    <v-alert dense type="error" v-if="this.errors?.length > 0">
      <ul>
        <li v-for="(error, _key, index) in this.errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </v-alert>
    <div>
      <v-row
        class="d-flex flex-row"
        align-content="center"
        justify="center"
        no-gutters
      >
        <v-col align-self="center" style="flex-grow: 0">
          <GondolaIcon :type="gondolaType" />
        </v-col>
        <v-col>
          <div
            class="d-flex flex-column align-center flex-grow-1 text-lg-h4 text-sm-h5 text-md-h5"
            style="color: #454845"
          >
            <span style="font-weight: 700" v-html="getSum"></span>
            <span class="type-text">{{ gondolaType.toLowerCase() }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-row" align-content="start" no-gutters>
        <v-col>
          <GondolaItem
            v-for="(gondola, index) in gondolas"
            :key="generateKeyToGondolaItem(gondola, index)"
            @changedInputValue="handleInputChange"
            :gondola="gondola"
            ref="gondolaItems"
          />
        </v-col>
      </v-row>
    </div>
    <v-row
      v-if="!this.contentGondolaIsEnabled || isUpdateButtonEnabled"
      class="ma-0 pa-1"
      justify="center"
      align-content="end"
    >
      <v-col class="ma-0 px-0 py-1">
        <v-btn
          block
          rounded
          large
          class="update-button no-text-transform"
          color="green"
          @click="addNewBags"
          :loading="btnLoading"
          :disabled="!isUpdateButtonEnabled"
        >
          <span class="update-button-text">ATUALIZAR</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else class="ma-0 pa-1" justify="center" align-content="end">
      <v-col class="ma-0 px-0 py-1">
        <v-btn
          block
          rounded
          large
          class="update-button no-text-transform"
          color="green"
          @click="createContentBagGondola"
        >
          <span class="update-button-text">CRIAR</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDialogContentCreatedBag"
      width="100%"
      height="50%"
      persistent
    >
      <div class="dialog-container">
        <BagWithContents
          v-if="this.initialGondolas"
          @updateGondola="updateGondolaCard"
          @closeBagWithContentDialog="closeProductsDialog"
          :partnerId="this.getPartnerId"
          :bagType="this.initialGondolas[0].cesta.tipo"
          :bagCategory="this.initialGondolas[0].cesta.category"
          :bagContent="this.initialGondolas[0].cesta.content"
          :bagDescription="this.initialGondolas[0].cesta.description"
          ref="bagWithContents"
        />
        <v-btn outlined class="my-0" @click="closeProductsDialog">
          Fechar
        </v-btn>
      </div>
    </v-dialog>
  </v-card>
</template>

<style scoped>
@import 'GondolaCard.scss';
</style>
