<script>
import { toMonetaryWithoutCents } from '@/util/Formater.js'
import { toDinheiro } from '@/util/Formater.js'
import { mapGetters, mapState } from 'vuex'
import { partnerConfigurationModelType } from '@/util/constants'
import GondolaContentDetails from '@/components/bag/GondolaContentDetails.vue'
import GondolaPriceDetails from '@/components/bag/GondolaPriceDetails.vue'
import { vMaska } from 'maska'

export default {
  name: 'GondolaItem',

  props: {
    gondola: Object
  },

  components: {
    GondolaContentDetails,
    GondolaPriceDetails
  },

  computed: {
    ...mapState(['estabelecimento']),
    ...mapGetters(['estabelecimentoTemCadastroPendente'])
  },
  directives: { maska: vMaska },
  mounted() {
    const allowCreateBagWithSellingPriceEnabled =
      this.$store.getters.getEstabelecimento?.enabled_configurations?.find(
        (feature) =>
          feature ===
          partnerConfigurationModelType.ALLOW_CREATE_BAG_WITH_SELLING_PRICE
      ) !== undefined
    this.allowCreateBagWithSellingPriceEnabled =
      allowCreateBagWithSellingPriceEnabled
  },

  data() {
    return {
      current_quantity: this.gondola.quantidade,
      item_count: this.gondola.quantidade ?? 0,
      change_intention_count: this.gondola.change_intention_count ?? 0,
      allowCreateBagWithSellingPriceEnabled: false,
      customReferencePrice: null,
      customPrice: null,
      customPriceGondolaIsEnabled: false,
      errorList: [],
      showBagContentDetails: false,
      showBagPriceDetails: false,
      gondolaBagId: ''
    }
  },

  methods: {
    toMonetaryWithoutCents,
    toDinheiro,

    partnerSignupIsComplete() {
      return this.$store.getters.estabelecimentoTemCadastroPendente
    },

    emitInputChange(wasChangedByClick) {
      if (!wasChangedByClick) {
        this.change_intention_count =
          Number(this.item_count) - Number(this.current_quantity ?? 0)
      }

      if (Number(this.item_count) < 0 || isNaN(Number(this.item_count))) return

      let formattedCustomReferencePrice
      let formattedCustomPrice

      if (
        this.customReferencePrice &&
        typeof this.customReferencePrice === 'string'
      ) {
        formattedCustomReferencePrice = parseFloat(
          this.customReferencePrice.replace(',', '.').replace(' ', '')
        )
      }

      if (this.customPrice && typeof this.customPrice === 'string') {
        formattedCustomPrice = parseFloat(
          this.customPrice.replace(',', '.').replace(' ', '')
        )
      }

      this.$emit('changedInputValue', {
        quantidade: Number(this.item_count),
        change_intention_count: Number(this.change_intention_count),
        id: this.gondola.cesta.id,
        price: this.gondola.cesta.valor,
        price_reference: this.gondola.cesta.valor_referencia,
        customReferencePrice: formattedCustomReferencePrice,
        customPrice: formattedCustomPrice,
        gondola: this.gondola,
        tag: this.gondola.cesta.tag,
        customPriceBag: Boolean(this.gondola.customPriceBag),
        eventType: this.getEventType()
      })
    },
    getEventType() {
      if (this.gondola.cesta.tag) return 'content'
      if (
        !this.gondola.cesta.id &&
        (this.customReferencePrice || Number(this.item_count) >= 0)
      )
        return 'custom'
      if (!this.gondola.cesta.valor_referencia) return 'void'
      return 'default'
    },
    incrementQuantity() {
      this.item_count++
      this.change_intention_count++
      this.emitInputChange(true)
    },
    decrementQuantity() {
      this.item_count--
      this.change_intention_count--
      this.emitInputChange(true)
    },
    openDetails() {
      if (this.gondola.cesta.gondola_bag_id) this.showBagContentDetails = true
    },
    openPriceDetailModal() {
      this.showBagPriceDetails = true
    },
    confirmPriceDetails(event) {
      this.showBagPriceDetails = false
      this.customReferencePrice = event.referencePrice
      this.customPrice = event.price
    },
    resetCustomPrices() {
      this.customPrice = null
      this.customReferencePrice = null
    }
  },
  computed: {
    ...mapState(['estabelecimento'])
  },
  watch: {
    gondola: {
      handler(cesta) {
        if (cesta) {
          this.item_count = cesta.quantidade
          this.current_quantity = cesta.quantidade
          this.change_intention_count = 0
        } else {
          this.item_count = 0
          this.current_quantity = 0
          this.change_intention_count = 0
        }
      }
    }
  }
}
</script>

<template>
  <div>
    <v-col cols="12" class="pa-0">
      <v-row justify="space-between" align="center" no-gutters class="py-1">
        <v-col
          lg="6"
          cols="6"
          @click="openDetails"
          :class="{
            'click-details': Boolean(this.gondola.cesta.gondola_bag_id)
          }"
        >
          <p class="ma-0 pa-0 text-lg-body-1 text-body-2 text-sm-caption">
            R$
            <strong v-if="gondola.cesta.valor_referencia">
              {{
                gondola.cesta.valor_referencia?.toLocaleString('pt-br', {
                  style: 'decimal',
                  minimumFractionDigits: 2
                })
              }}
            </strong>
            <strong v-if="!gondola.cesta.valor_referencia">
              <input
                type="text"
                class="bag-custom-price"
                v-model="customReferencePrice"
                maxlength="6"
                @change="emitInputChange"
                placeholder="0,00"
                v-maska
                data-maska="0,99"
                data-maska-tokens="0:\d:multiple|9:\d:optional"
              />
            </strong>
            <strong>{{ gondola.cesta.tag }}</strong>
            <v-btn
              text
              icon
              color="primary"
              v-if="
                !gondola.cesta.valor_referencia &&
                allowCreateBagWithSellingPriceEnabled
              "
              @click="openPriceDetailModal"
            >
              <v-icon> mdi-note-edit-outline </v-icon>
            </v-btn>
          </p>
        </v-col>
        <v-col lg="6" cols="6">
          <div class="d-flex justify-end">
            <v-btn
              fab
              text
              width="28"
              height="28"
              @click="decrementQuantity"
              :disabled="partnerSignupIsComplete() || Number(item_count) === 0"
            >
              <img
                width="14"
                height="14"
                src="@/assets/images/akar-icons_circle-minus-fill.svg"
              />
            </v-btn>
            <input
              type="text"
              class="bag-item-count"
              v-model="item_count"
              maxlength="2"
              @change="emitInputChange(false)"
              v-maska
              data-maska="0"
              data-maska-tokens="0:\d:multiple"
            />
            <v-btn
              fab
              text
              width="28"
              height="28"
              @click="incrementQuantity"
              :disabled="partnerSignupIsComplete() || Number(item_count) >= 99"
            >
              <img
                width="14"
                height="14"
                src="@/assets/images/akar-icons_circle-plus-fill.svg"
              />
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <div class="dialog-content-container">
      <GondolaContentDetails
        v-if="gondola.cesta.valor_referencia"
        v-model="showBagContentDetails"
        :gondolaBagId="gondola.cesta.gondola_bag_id"
      />
    </div>
    <div class="dialog-content-container">
      <GondolaPriceDetails
        v-if="allowCreateBagWithSellingPriceEnabled"
        v-model="showBagPriceDetails"
        :customPrice="customPrice"
        :customReferencePrice="customReferencePrice"
        @confirmPriceDetails="confirmPriceDetails"
      />
    </div>
  </div>
</template>

<style scoped>
span.moeda {
  font-size: 0.5rem;
  font-weight: bold;
}
input.bag-custom-price {
  width: 56px;
  text-align: left;
  font-size: 16px;
  border-style: outset;
}
input.bag-item-count {
  width: 20px;
  text-align: center;
  font-size: 13px;
}
.dialog-content-container {
  display: none;
}
.click-details {
  cursor: pointer;
}
input.bag-custom-price::placeholder {
  color: #615f61;
  font-weight: 500;
  text-align: right;
}
</style>
