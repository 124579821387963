<template>
  <nav id="top-bar" v-bind:style="[showMenu ? { 'min-height': '200px' } : {}]">
    <div class="d-flex flex-row align-center top-bar">
      <div class="d-flex flex-row align-center">
        <a href="https://parceiro.foodtosave.com.br">
          <img
            fluid
            class="logo"
            src="@/assets/images/logo_FTS_rebranding.png"
            alt="Food To Save Logo"
          />
        </a>
        <div class="hide" v-if="showOptions">
          <v-row align="center">
            <v-col cols="8" class="">
              <p class="text-sm-body-2 align-center mb-0 mt-4">
                Horário de Retirada:
              </p>
              <p>
                <strong>{{ formatTime(startHour) }}</strong> às
                <strong>{{ formatTime(endHour) }}</strong>
              </p>
            </v-col>
            <v-col cols="3" class="pa-0">
              <v-btn
                @click="sendChange"
                rounded
                color="#697269"
                small
                style="margin: 10px; color: #ffffff"
                v-if="
                  this.$router.history.current.path !== '/login' &&
                  startHour &&
                  endHour
                "
              >
                ALTERAR
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>

      <div
        class="hide"
        v-if="showOptions || selfSignUp"
        style="position: absolute; right: 20px"
      >
        <v-row class="d-flex align-center">
          <v-col v-if="showOptions">
            <v-row class="d-flex align-center">
              <div class="d-none d-lg-block">
                <v-menu
                  transition="slide-y-transition"
                  offset-y
                  nudge-left="25"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="help-center-button"
                      color="#fff"
                      depressed
                      v-bind="attrs"
                      v-on="on"
                    >
                      CENTRAL DE AJUDA <v-icon right> mdi-chevron-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="clickOpenFastGuide">
                      <v-list-item-icon class="mr-1">
                        <v-icon class="ma-0" right dark color="#FF6600"
                          >mdi-play-circle</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="help-center-text"
                          >Ver guia rápido</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="clickOpenHelpCenter">
                      <v-list-item-icon class="mr-1">
                        <v-icon class="ma-0" right dark color="#FF6600"
                          >mdi-help-circle</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="help-center-text"
                          >Acessar central de ajuda</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="d-lg-none">
                <v-menu
                  transition="slide-y-transition"
                  offset-y
                  nudge-left="25"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon center dark color="#FF6600" size="35px">
                        mdi-help-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="clickOpenFastGuide">
                      <v-list-item-icon class="mr-1">
                        <v-icon class="ma-0" right dark color="#FF6600"
                          >mdi-play-circle</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="help-center-text"
                          >Ver guia rápido</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="clickOpenHelpCenter">
                      <v-list-item-icon class="mr-1">
                        <v-icon class="ma-0" right dark color="#FF6600"
                          >mdi-help-circle</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="help-center-text"
                          >Acessar central de ajuda</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-row>
          </v-col>
          <v-col v-if="showOptions" class="d-lg-none d-lg-block pa-0">
            <div>
              <v-menu offset-y rounded="xl - xl">
                <template v-slot:activator="{ on, props }">
                  <v-btn
                    fab
                    text
                    x-large
                    stacked
                    color="#FF6600"
                    v-bind="props"
                    v-on="on"
                    @click="sendGoogleAnalyticsEvent('see_notifications_list')"
                  >
                    <v-badge
                      v-if="notificationsUnread > 0"
                      :content="notificationsUnread"
                    >
                      <v-icon>mdi-message-text-outline</v-icon>
                    </v-badge>
                    <v-icon v-if="notificationsUnread == 0"
                      >mdi-message-text-outline</v-icon
                    >
                  </v-btn>
                </template>
                <v-list v-if="notifications.length > 0">
                  <v-list-item
                    color="#838383"
                    style="font-size: 1.2em"
                    align-self-center
                    >Notificações</v-list-item
                  >
                  <v-list-item
                    v-for="(item, i) in notifications"
                    :key="i"
                    @click="openNotificationDetail(item, i)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap"
                        style="font-weight: bold; font-size: 0.9em"
                        >{{ item.title }}</v-list-item-title
                      >
                      <v-list-item-subtitle
                        class="text-wrap"
                        style="font-size: 0.7em"
                        >{{ item.content }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        class="text-wrap"
                        style="font-size: 0.7em"
                        >{{
                          'Food To Save - ' + formatLocaleDate(item.created_at)
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-icon>
                      <span v-if="item.viewedAt == null">
                        <v-icon x-small color="primary">mdi-circle</v-icon>
                      </span>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
          <v-col v-if="showOptions" class="d-lg-none d-lg-block pa-0">
            <v-btn
              fab
              text
              x-large
              @click="toggleMuteSound"
              :style="{ color: [habilitaSom ? '#FF6600' : null] }"
            >
              <v-icon v-if="habilitaSom">mdi-volume-high</v-icon>
              <v-icon v-else>mdi-volume-off</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-row>
              <div class="d-flex flex-row-reverse align-items-center">
                <div class="logo-container" :class="['logo-container-top']">
                  <img fluid :src="logoUrl" />
                </div>
                <div class="name-container">
                  <p class="name mb-0" style="white-space: nowrap">
                    {{ name }}
                  </p>
                </div>
              </div>
            </v-row>
          </v-col>
          <v-btn fab text x-large @click="logout" :style="{ color: '#FF6600' }">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </v-row>
      </div>
      <div class="collapse-menu" v-if="showOptions">
        <v-row>
          <v-btn
            fab
            text
            x-large
            class="topbar-button"
            @click="toggleMuteSound"
            :style="{ color: [habilitaSom ? '#FF6600' : null], font: [35] }"
          >
            <v-icon v-if="habilitaSom">mdi-volume-high</v-icon>
            <v-icon v-else>mdi-volume-off</v-icon>
          </v-btn>
          <div class="d-flex flex-row align-items-center btn-menu">
            <v-btn
              text
              :class="showMenu ? null : 'collapsed'"
              :aria-expanded="showMenu ? 'true' : 'false'"
              aria-controls="collapse-1"
              @click="toggleMenu"
            >
              <img src="@/assets/images/menu.svg" alt="menu" />
            </v-btn>
          </div>
          <div>
            <v-menu offset-y rounded="xl - xl">
              <template v-slot:activator="{ on, props }">
                <v-btn
                  fab
                  text
                  x-large
                  stacked
                  color="primary"
                  v-bind="props"
                  v-on="on"
                  @click="sendGoogleAnalyticsEvent('see_notifications_list')"
                >
                  <v-badge
                    v-if="notificationsUnread > 0"
                    :content="notificationsUnread"
                  >
                    <v-icon>mdi-message-text-outline</v-icon>
                  </v-badge>
                  <v-icon v-if="notificationsUnread == 0"
                    >mdi-message-text-outline</v-icon
                  >
                </v-btn>
              </template>
              <v-list v-if="notifications.length > 0">
                <v-list-item
                  color="#838383"
                  style="font-size: 1.2em"
                  align-self-center
                  >Notificações</v-list-item
                >
                <v-list-item
                  v-for="(item, i) in notifications"
                  :key="i"
                  @click="openNotificationDetail(item, i)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-wrap"
                      style="font-weight: bold; font-size: 0.9em"
                      >{{ item.title }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      class="text-wrap"
                      style="font-size: 0.7em"
                      >{{ item.content }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      class="text-wrap"
                      style="font-size: 0.7em"
                      >{{
                        'Food To Save - ' + formatLocaleDate(item.created_at)
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-icon>
                    <span v-if="item.viewedAt == null">
                      <v-icon x-small color="primary">mdi-circle</v-icon>
                    </span>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-row>
      </div>
    </div>
    <div v-if="showMenu" class="collapse-menu">
      <v-row align="center" justify="center" class="top-bar">
        <v-col cols="7" sm="9">
          Horário de Retirada: <strong>{{ startHour }}</strong> às
          <strong>{{ endHour }}</strong>
        </v-col>
        <v-col cols="5" sm="3">
          <v-btn
            @click="sendChange"
            rounded
            color="#697269"
            style="margin: 10px; color: #ffffff"
            v-if="
              this.$router.history.current.path !== '/login' &&
              startHour &&
              endHour
            "
          >
            ALTERAR
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <div class="d-flex flex-row-reverse align-center justify-center">
            <v-btn fab text small @click="logout" :style="{ color: '#FF6600' }">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
            <div class="logo-container" style="position: relative; right: 25px">
              <img :src="logoUrl" />
            </div>
            <div class="name-container" style="max-height: 48px">
              <p class="name" style="white-space: nowrap; font-size: 12px">
                {{ name }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="showOrderDetail" max-width="50rem">
      <order-detail
        v-if="this.orderId"
        :key="this.orderId"
        :orderId="this.orderId"
        :partnerId="this.partnerId"
      />
    </v-dialog>
  </nav>
</template>

<script>
import { WebSocketHandler } from '/src/util/websocket'
import { formatLocaleDate } from '@/util/Formater.js'
import OrderDetail from '@/components/order/OrderDetail.vue'

export default {
  name: 'TopBar',
  components: {
    OrderDetail: OrderDetail
  },
  data: () => ({
    showMenu: false,
    orderId: null,
    deviceWidth: 0,
    notificationsLoading: false,
    showOrderDetail: false,
    notifications: [],
    notificationsUnread: 0
  }),
  computed: {
    items() {
      return this.notifications
    }
  },
  props: {
    showOptions: Boolean,
    selfSignUp: Boolean,
    logoUrl: String,
    name: String,
    startHour: String,
    endHour: String,
    habilitaSom: Boolean,
    partnerId: {
      require: true
    }
  },

  methods: {
    formatLocaleDate,
    openNotificationDetail: function (partnerNotification, index) {
      if (partnerNotification.data) {
        var notification = JSON.parse(partnerNotification.data)
        if (
          partnerNotification.notification_action === 'ORDER' &&
          notification.hasOwnProperty('orderId')
        ) {
          this.orderId = notification.orderId
          this.showOrderDetail = true
        }
      }

      if (partnerNotification.viewedAt == null) {
        this.$set(
          this.notifications,
          index,
          Object.assign({}, partnerNotification, { viewedAt: Date.now() })
        )
        this.readNotification(partnerNotification)
        this.notificationsUnread = this.notificationsUnread - 1
      }
      this.sendGoogleAnalyticsEvent('open_notifications')
    },
    sendChange() {
      this.$emit('openChangeHourModal'),
        this.sendGoogleAnalyticsEvent('opening_hours')
    },
    logout() {
      try {
        const persistentStoredKeys = ['food_onboarding_shown']
        const storedKeysToBeDeleted = Object.keys(localStorage).filter(
          (key) => !persistentStoredKeys.includes(key)
        )
        storedKeysToBeDeleted.forEach((key) => {
          localStorage.removeItem(key)
        })
      } catch (_) {
        localStorage.clear()
      }
      sessionStorage.clear()

      const wsHandler = new WebSocketHandler(this)
      wsHandler.disconnectWebsocket()

      this.$store.commit('logout')
      this.$router.push({ name: 'login' })
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    toggleMuteSound() {
      this.$emit('toggleMuteSound')
      this.sendGoogleAnalyticsEvent('received_order_sound')
    },
    getNotifications() {
      this.erros = []
      this.notificationsLoading = true
      this.$http
        .get(`/v1/partners/${this.partnerId}/notifications`)
        .then((response) => {
          this.notifications = response.data
          this.notificationsUnread = this.notifications.length
        })
        .catch((error) => {
          this.erros.push(error.message)
        })
        .finally(() => {
          this.notificationsLoading = false
        })

      return this.notifications
    },
    readNotification(notification) {
      this.erros = []
      this.notificationsLoading = true

      const request = {
        id: notification.id,
        viewed_at: new Date()
      }

      this.$http
        .put(`/v1/partners/${this.partnerId}/notifications`, request)
        .catch((error) => {
          this.erros.push(error.message)
        })
        .finally(() => {
          this.notificationsLoading = false
        })
      return this.notifications
    },
    sendGoogleAnalyticsEvent(eventName) {
      this.$gtag.event(eventName)
    },
    clickOpenFastGuide() {
      this.sendGoogleAnalyticsEvent('show_tutorial_video')
      this.$emit('showTutorialVideoDialog')
    },
    clickOpenHelpCenter() {
      window.open('https://link.foodtosave.com.br/centraldeajuda', '_blank')
      this.sendGoogleAnalyticsEvent('help_center')
    },
    formatTime(timeString) {
      if (!timeString) return ''
      const [hours, minutes] = timeString.split(':')
      return `${hours}:${minutes}`
    }
  },

  mounted() {
    this.getNotifications()
    this.$root.$on('callNotificationModal', (notificationData) => {
      this.notifications.unshift(notificationData)
      this.notificationsUnread = this.notificationsUnread + 1
      return this.notifications
    })
  }
}
</script>

<style scoped>
@import './TopBar.scss';
</style>
