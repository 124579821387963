<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Erro from '@/components/shared/Erro.vue'
import { formatLocaleDate, toDinheiro, toTelefone } from '@/util/Formater.js'
import TopBar from '@/components/shared/TopBar.vue'
import OrdersTable from '@/components/order/OrdersTable.vue'
import InvoicesTable from '@/components/invoices/InvoicesTable.vue'
import StatisticsView from '@/components/stats/StatisticsView.vue'
import KrafBagRequestForm from '../components/kraftbag/KrafBagRequestForm.vue'
import { partnerConfigurationModelType } from '@/util/constants'
import GondolaSection from '@/components/bag/GondolaSection'
import { BlipChat } from 'blip-chat-widget'

export default {
  name: 'MainPage',
  components: {
    'erro-msg': Erro,
    DatePicker: DatePicker,
    TopBar: TopBar,
    OrdersTable: OrdersTable,
    StatisticsView: StatisticsView,
    InvoicesTable: InvoicesTable,
    KrafBagRequestForm,
    GondolaSection
  },
  computed: {
    ...mapState(['estabelecimento', 'loading'])
  },
  data() {
    return {
      user: null,
      horaInicioRetirada: null,
      horaFinalRetirada: null,
      showDialogNovoPedido: false,
      showTutorialVideo:
        this.$store.state.selfSignUp?.showTutorialVideo ?? false,
      pedido: null,
      showDetalhePedido: false,
      showDialogHorarioFuncionamento: false,
      pedidoId: null,
      method: 'Adicionar',
      pedidos: [],
      globalErrors: [],
      gondolasWithoutBagsMap: null,
      erros: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
      showDialogConfirm: false,
      habilitaSom: true,
      screenSize: this.$vuetify.breakpoint.name,
      loadingHours: false,
      showDialogHorarioFuncionamentoOngoingTakeoutError: false
    }
  },

  created() {
    if (this.estabelecimento === null) {
      this.$router.push({ name: '' })
      return
    } else {
      const customPriceGondolaIsEnabled =
        this.estabelecimento.enabled_configurations?.find(
          (feature) =>
            feature === partnerConfigurationModelType.BAG_DYNAMIC_PRICE_MODEL
        ) !== undefined

      const contentGondolaBagIsEnabled =
        this.estabelecimento.enabled_configurations?.find(
          (feature) =>
            feature === partnerConfigurationModelType.BAG_WITH_CONTENT_MODEL
        ) !== undefined

      const hiddenKraftBagRequestIsEnabled =
        this.estabelecimento.enabled_configurations?.find(
          (feature) =>
            feature === partnerConfigurationModelType.HIDE_KRAFT_BAG_REQUEST
        ) !== undefined

      this.customPriceGondolaIsEnabled = customPriceGondolaIsEnabled
      this.contentGondolaBagEnabled = contentGondolaBagIsEnabled
      this.hiddenKraftBagRequestIsEnabled = hiddenKraftBagRequestIsEnabled
      this.horaInicioRetirada = this.estabelecimento.hora_inicio_retirada
      this.horaFinalRetirada = this.estabelecimento.hora_final_retirada
    }

    // Start Blip Chat Widget Script
    new BlipChat()
      .withAppKey(
        'Zm9vZHRvc2F2ZWIyYjo0ZDA4OGY5OC1mOTI2LTQxM2MtOGFlYi03Y2FkNmZhMDU4OGU='
      )
      .withButton({ color: '#FF6600', icon: '' })
      .withCustomCommonUrl('https://foodtosave.chat.blip.ai/')
      .withAuth({
        authType: BlipChat.DEV_AUTH,
        userIdentity: this.estabelecimento.email,
        userPassword: 'MTIzNDU2'
      })
      .withAccount({
        fullName: this.estabelecimento.nome,
        email: this.estabelecimento.email
      })
      .build()
  },
  mounted() {
    this.user = this.$store.getters.usuarioLogado
    if (!this.user) {
      this.$router.push({ name: 'login' })
      return
    }

    this.$store
      .dispatch('shouldRedirectToSelfSignUpOnboarding')
      .then((shouldRedirectToSelfSignUpOnboarding) => {
        if (shouldRedirectToSelfSignUpOnboarding) {
          this.$gtag.event('first_access_selfsignup_pending')

          return this.$router.push({
            name: 'self-sign-up-bag-management'
          })
        }
      })
  },
  methods: {
    ...mapMutations(['setEstabelecimento']),
    toDinheiro,
    toTelefone,

    openDialogHorarioFuncionamento() {
      this.showDialogHorarioFuncionamento = true
    },

    atualizaHorario() {
      this.erros = []

      if (!this.horaInicioRetirada || !this.horaFinalRetirada) {
        this.erros.push('Informe os novos horários.')
      }

      if (this.erros && this.erros.length > 0) {
        return
      }

      const data = {
        hora_inicio_retirada: this.horaInicioRetirada,
        hora_final_retirada: this.horaFinalRetirada
      }

      this.$gtag.event('opening_hours_edit', {
        partner_id: this.estabelecimento.id,
        hour_change: data.hora_inicio_retirada
      })

      this.loadingHours = true
      this.$http
        .put(`/v1/parceiro/${this.estabelecimento.id}/hora_retirada`, data)
        .then(() => {
          this.estabelecimento.hora_inicio_retirada = this.horaInicioRetirada
          this.estabelecimento.hora_final_retirada = this.horaFinalRetirada
          this.setEstabelecimento(this.estabelecimento)
          this.showDialogHorarioFuncionamento = false
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.showDialogHorarioFuncionamentoOngoingTakeoutError = true
            this.showDialogHorarioFuncionamento = false
          } else {
            this.erros = []
            this.erros.push(error.response.data)
          }
        })
        .finally(() => {
          this.loadingHours = false
        })
    },

    closeDialogRefuseOngoingTakeoutError() {
      this.erros = []
      this.showDialogHorarioFuncionamentoOngoingTakeoutError = false
      this.showDialogHorarioFuncionamento = false
    },

    updateWithTakeOutOrders() {
      this.$http
        .put(`/v1/parceiro/${this.estabelecimento.id}/hora_retirada`, {
          hora_inicio_retirada: this.horaInicioRetirada,
          hora_final_retirada: this.horaFinalRetirada,
          updateWithTakeOutOrders: true
        })
        .then(() => {
          this.estabelecimento.hora_inicio_retirada = this.horaInicioRetirada
          this.estabelecimento.hora_final_retirada = this.horaFinalRetirada
          this.setEstabelecimento(this.estabelecimento)
        })
        .catch((error) => {
          this.erros = []
          this.erros.push(error.response.data)
        })
        .finally(() => {
          this.loadingHours = false
          this.showDialogHorarioFuncionamento = false
          this.showDialogHorarioFuncionamentoOngoingTakeoutError = false
        })
    },

    closeDialogConfirm() {
      this.erros = []
      this.showDialogNovoPedido = false
    },

    showTutorialVideoDialog() {
      this.showTutorialVideo = true
    },
    closeTutorialVideoDialog() {
      try {
        const iframe = this.$refs.partnerAdminTutorial?.contentWindow
        iframe.postMessage(
          '{"event":"command","func":"stopVideo","args":""}',
          '*'
        )
      } catch (_) {
        // Catch possible errors due to possible calls of postMessage on unsuported browsers
      }
      this.showTutorialVideo = false
    },

    toggleMuteSound() {
      this.habilitaSom = !this.habilitaSom
    },

    async getGondolasEvent() {
      await this.$refs.gondolaSection.reloadGondolasMap()
    },

    newOrderArrived() {
      this.showDialogNovoPedido = true
      if (this.habilitaSom) {
        try {
          document.getElementById('myAudio').volume = 1
          document.getElementById('myAudio').play()
        } catch (error) {
          this.$gtag.event('error_playing_audio', { error })
        }
      }
    },

    addGlobalError(event) {
      if (event) {
        this.globalErrors.push(event)
      }
    },
    clearGlobalErrors() {
      this.globalErrors = []
    },

    formatLocaleDate
  }
}
</script>

<template class="conteudo">
  <v-app>
    <top-bar
      :showOptions="
        !!estabelecimento && this.$router.history.current.path !== '/login'
      "
      :logoUrl="estabelecimento && estabelecimento.url_logo"
      :name="estabelecimento && estabelecimento.nome"
      :startHour="estabelecimento && estabelecimento.hora_inicio_retirada"
      :endHour="estabelecimento && estabelecimento.hora_final_retirada"
      :partnerId="estabelecimento && estabelecimento.id"
      :habilitaSom="habilitaSom"
      @openChangeHourModal="openDialogHorarioFuncionamento"
      @toggleMuteSound="toggleMuteSound"
      @showTutorialVideoDialog="showTutorialVideoDialog"
      :loadingHours="loadingHours"
    />
    <v-container fluid class="content" v-if="globalErrors.length > 0">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-alert
              v-for="e in globalErrors"
              :key="e"
              dense
              type="warning"
              color="#E3B740"
              :icon="false"
              ><strong>Atenção:</strong> {{ e }}</v-alert
            >
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container fluid class="flex-grow-1 content">
      <v-row>
        <v-col md="5" cols="12">
          <v-progress-circular
            v-if="!estabelecimento"
            indeterminate
            color="primary"
          />

          <v-row>
            <v-col cols="3" sm="2" md="2" xl="1" class="pr-0">
              <p class="sections-title">Vendas</p>
            </v-col>
            <v-col
              cols="9"
              sm="10"
              md="10"
              xl="11"
              class="pl-0"
              style="display: flex; align-items: center"
            >
             
            </v-col>
          </v-row>
          <statistics-view
            v-if="estabelecimento"
            :partner_id="estabelecimento.id"
          />

          <p class="sections-title mb-0">Sacolas Surpresa disponíveis</p>

          <v-row>
            <v-col md="12" dense>
              <gondola-section
                ref="gondolaSection"
                @addGlobalError="addGlobalError"
                @clearGlobalErrors="clearGlobalErrors"
              />
            </v-col>
          </v-row>
          <div class="invoices-container-hide">
            <p class="sections-title">Faturas e notas fiscais</p>
            <invoices-table :estabelecimento="estabelecimento" />
          </div>
          <v-row class="invoices-container-bottom-hide">
            <v-col>
              <p class="sections-title">Faturas e notas fiscais</p>
              <invoices-table :estabelecimento="estabelecimento" />
            </v-col>
          </v-row>

          <v-row
            v-if="!this.hiddenKraftBagRequestIsEnabled"
            class="kraft-bag-requests"
          >
            <v-col>
              <p class="sections-title">Solicite Sacola Kraft</p>
              <kraf-bag-request-form :partner="estabelecimento" />
            </v-col>
          </v-row>
        </v-col>
        <v-col md="7" cols="12">
          <section v-if="pedidos" style="min-height: 100%">
            <p class="sections-title">Pedidos</p>
            <orders-table
              :estabelecimento="estabelecimento"
              @newOrderArrived="newOrderArrived"
              @getGondolasEvent="getGondolasEvent"
            />
          </section>
        </v-col>
      </v-row>

      <v-dialog v-model="showTutorialVideo" width="35%" persistent>
        <div class="dialog-container">
          <iframe
            ref="partnerAdminTutorial"
            src="https://www.youtube.com/embed/wCLT1mnGS7A?si=UbgVNMgwXk0DC2gE&rel=0&enablejsapi=1"
            title="Tutorial Painel do Parceiro - Food To Save"
            class="tutorial-video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          <button class="button" @click="closeTutorialVideoDialog">
            Fechar
          </button>
        </div>
      </v-dialog>

      <v-dialog v-model="showDialogNovoPedido" max-width="25rem">
        <div class="dialog">
          <span class="text-h5 mb-4 d-flex justify-center">
            Chegou um novo pedido!
          </span>
          <button class="button" @click="closeDialogConfirm">Fechar</button>
        </div>
      </v-dialog>
      <v-dialog max-width="25rem" v-model="showDialogHorarioFuncionamento">
        <div class="dialog">
          <v-row>
            <v-col class="text-center">
              <h1>Horário de Retirada</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>Início:</v-col>
            <v-col
              ><date-picker
                v-model="horaInicioRetirada"
                type="time"
                format="HH:mm"
                valueType="format"
              ></date-picker
            ></v-col>
          </v-row>
          <v-row>
            <v-col>Final:</v-col>
            <v-col
              ><date-picker
                v-model="horaFinalRetirada"
                type="time"
                format="HH:mm"
                valueType="format"
              ></date-picker
            ></v-col>
          </v-row>
          <button
            class="button"
            :disabled="
              horaInicioRetirada === estabelecimento.hora_inicio_retirada &&
              horaFinalRetirada === estabelecimento.hora_final_retirada
            "
            @click="atualizaHorario"
            :loading="loadingHours"
          >
            Salvar
            <v-progress-circular
              v-if="loadingHours"
              indeterminate
              color="white"
              :size="25"
            />
          </button>
          <erro-msg :erros="erros"></erro-msg>
        </div>
      </v-dialog>

      <v-dialog
        max-width="25rem"
        v-model="showDialogHorarioFuncionamentoOngoingTakeoutError"
      >
        <div class="dialog">
          <v-row>
            <v-col class="text-center">
              <p>
                Notamos que existem pedidos realizados que ainda não foram
                coletados, deseja alterar o horário mesmo assim?
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <button
                class="button"
                @click="updateWithTakeOutOrders"
                :loading="loadingHours"
              >
                Sim
                <v-progress-circular
                  v-if="loadingHours"
                  indeterminate
                  color="white"
                  :size="25"
                />
              </button>
            </v-col>
            <v-col>
              <button
                class="button"
                @click="closeDialogRefuseOngoingTakeoutError"
              >
                Não
              </button>
            </v-col>
          </v-row>
          <erro-msg :erros="erros"></erro-msg>
        </div>
      </v-dialog>

      <audio id="myAudio" volume="1" muted>
        <source src="@/assets/trim.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </v-container>
  </v-app>
</template>

<style scoped>
@import '../style/main_board.scss';
</style>
