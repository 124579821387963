import axios from 'axios'

import { router } from '../main'
import { WebSocketHandler } from '../util/websocket'

const sessionId = Math.random().toString(36).slice(2);

const baseApi = axios.create({
  // baseURL: 'https://partner-api.foodtosave.com.br/api'
  // baseURL: "https://partner-test.foodtosave.com.br/api",
  // baseURL: 'http://localhost:8080/api'
  // baseURL: "http://partner-api.sandbox.foodtosave.com.br/api"
  baseURL: process.env.VUE_APP_API_URL || 'https://partner-api.foodtosave.com.br/api'
})

baseApi.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem('access_token')
    const accessTokenHeader = `Bearer ${accessToken}`
    config.headers = {
      'X-Session-Id': sessionId,
      'X-Trace-Id': Math.random().toString(36).slice(2),
      'Authorization': accessTokenHeader
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

baseApi.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (shouldFetchNewAuthToken(error)) {
      refreshToken(error)
        .then((res) => {
          if (res.status == 200) {
            return baseApi.request(error.config)
          } else {
            resetSession()
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            resetSession()
          }
          return Promise.reject(error)
        })
    } else if (error.response.status == 401) {
      resetSession()
    }
    return Promise.reject(error)
  }
)

function resetSession() {
  router.push({ name: 'login' })
  sessionStorage.clear()
  const wsHandler = new WebSocketHandler();
  wsHandler.disconnectWebsocket()
  return
}

async function refreshToken(error) {
  return new Promise((resolve, reject) => {
    try {
      const access_token = sessionStorage.getItem('access_token')
      const refreshToken = sessionStorage.getItem('refresh_token')
      const header = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`
      }
      const parameters = {
        method: 'POST',
        headers: header
      }
      const body = {
        refresh_token: refreshToken
      }
      baseApi
        .post('/v1/authentication/refresh-token', body, parameters)
        .then(async (res) => {
          sessionStorage.setItem('access_token', res.data.access_token)
          sessionStorage.setItem('refresh_token', res.data.refresh_token)
          return resolve(res)
        })
        .catch(() => {
          return reject(error)
        })
    } catch (err) {
      return reject(err)
    }
  })
}

function shouldFetchNewAuthToken(error) {
  const refreshTokenEndpoint = '/v1/authentication/refresh-token'
  return (
    error.response.status === 401 &&
    error.response.config.url != refreshTokenEndpoint &&
    error.response.data.message == 'Falha na autenticação, token inválido!'
  )
}

export default baseApi
