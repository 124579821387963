<script>
import {
  toDinheiro,
  toTelefone,
  formatLocaleDate,
  toZipCode
} from '@/util/Formater.js'
import MapView from '../map/MapView.vue'

export default {
  name: 'OrderDetail',
  components: {
    MapView: MapView
  },
  props: {
    orderId: {
      require: true
    },
    partnerId: {
      require: true
    }
  },
  data() {
    return {
      order: null,
      showSuccessAlert: false,
      showErrorAlert: false,
      deliveryDetails: null,
      markers: null,
      orderDetailsLoading: false,
      deliveryDetailsLoading: false,
      cancellationReasonsToShow: [
        'ITEMS_EXPIRED',
        'PRODUCT_UNAVAILABLE',
        'ORDER_DOES_NOT_MATCH_THE_VALUE'
      ]
    }
  },
  model: {},
  methods: {
    toDinheiro,
    toTelefone,
    formatLocaleDate,
    toZipCode,
    uptateMarkers() {
      this.markers = [
        {
          name: 'origin',
          point: [
            this.order.estabelecimento.endereco.latitude,
            this.order.estabelecimento.endereco.longitude
          ],
          pin: {
            url: this.order.estabelecimento.url_logo
          }
        },
        {
          name: 'destiny',
          point: [
            this.order.endereco_entrega.latitude,
            this.order.endereco_entrega.longitude
          ]
        }
      ]

      if (
        this.deliveryDetails &&
        this.deliveryDetails.courier_info &&
        this.deliveryDetails.courier_info.last_position.latitude &&
        this.deliveryDetails.courier_info.last_position.longitude
      ) {
        this.markers.push({
          name: 'courier',
          point: [
            this.deliveryDetails.courier_info.last_position.latitude,
            this.deliveryDetails.courier_info.last_position.longitude
          ]
        })
      }
    },
    getOrderDetails() {
      this.erros = []
      this.orderDetailsLoading = true
      this.$http
        .get(`/v1/partners/${this.partnerId}/orders/${this.orderId}`)
        .then((response) => {
          this.order = response.data
          this.uptateMarkers()
          this.getDeliveryDetails()
        })
        .catch((error) => {
          this.erros.push(error.message)
        })
        .finally(() => {
          this.orderDetailsLoading = false
        })
      this.$gtag.event('order_list_item')
    },
    getDeliveryDetails() {
      this.erros = []
      this.deliveryDetailsLoading = true
      this.$http
        .get(
          `/v1/partners/${this.order.estabelecimento.id}/orders/${this.order.uuid}/delivery-details`
        )
        .then((response) => {
          this.deliveryDetails = response.data
          this.uptateMarkers()
          this.autoReload()
        })
        .catch((error) => {
          this.erros.push(error.message)
        })
        .finally(() => {
          this.deliveryDetailsLoading = false
        })
    },
    autoReload() {
      if (!this.order || !this.order.status === 'DELIVERY') {
        return
      }
      setTimeout(this.getDeliveryDetails, 30000)
    },
    openPopup(urlNewWindow) {
      window.open(urlNewWindow, '_blank')
    },
    async print() {
      let prtHtml = ''
      let orderItem = null

      // Get all stylesheets HTML
      let stylesHtml = ''
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style')
      ]) {
        stylesHtml += node.outerHTML
      }

      prtHtml = `
        <div id="order-invoice-print">
            <div class="container"
                style="border: 1px dashed; padding: 12px; max-width: 260px; font-size: 12px;">
                <div class="col" style="padding: 12px">
                    <div class="row" style="margin: 0px; padding: 0px">
                        <div class="col" style="padding: 0px">
                            <p style="font-weight: bold; font-size: 1.1em; margin: 0px">
                                #${this.order.id}
                            </p>
                        `
      if (this.order.external_id) {
        prtHtml += `
                             <p style="font-weight: bold; font-size: 0.9em; margin: 0px">
                                  ${this.order.external_id}
                             </p>`
      }

      prtHtml += `
                      </div>
                        <div class="col" style="padding: 0px">
                            <p style="
                          margin: 0px;
                          font-weight: bold;
                          font-size: 1.1em;
                          text-align: right;
                        ">
                                <strong> ${formatLocaleDate(
                                  this.order.created_at
                                )} </strong>
                            </p>
                        </div>
                    </div>
                    <div class="row" style="padding: 0px; margin: 0px; margin-top: 16px">
                        <div class="col" style="padding: 0px; margin: 0px">
                            <p style="font-weight: bold; font-size: 1.1em; text-align: center">
                                PEDIDO
                            </p>
                        </div>
                    </div>`

      for (let i = 0; i < this.order.itens.length; i++) {
        orderItem = this.order.itens[i]
        prtHtml += ` <div class="row mt-0" style="padding: 12px; margin-top: 0px; font-size: 0.9em">
                        <div class="col" style="padding: 0px; width: 100%; flex-grow: 1 !important"><strong style="font-size: 1.2em">
                                ${orderItem.quantidade}
                            </strong>
                            x ${
                              orderItem.cesta.category &&
                              orderItem.cesta.category !== ''
                                ? orderItem.cesta.category + ' - '
                                : ''
                            } ${orderItem.cesta.tipo}
                            ${
                              orderItem.tag
                                ? ` - <strong>#${orderItem.tag}</strong>`
                                : ''
                            }
                            <br />
                            <sup style="text-decoration-line: line-through;">
                                ${toDinheiro(orderItem.cesta.valor_referencia)}
                            </sup>
                            ${toDinheiro(orderItem.cesta.valor)}
                        </div>
                        <div class="col" style="
                        padding: 0px;
                        flex-shrink: 1 !important;
                        flex-basis: 0;
                        flex-grow: 0 !important;
                      ">
                            <p style="margin-bottom: 0px; text-align: right">
                                ${toDinheiro(orderItem.cesta.valor)}
                            </p>
                        </div>
                      </div>`
      }
      prtHtml += `<div class="row" style="padding: 0px; margin: 0px; margin-top: 12px">
                            <div class="col" style="padding: 0px">
                                <p style="text-align: right; margin-bottom: 0px">
                                    Sub-total: ${toDinheiro(
                                      this.order.itens.reduce((acc, i) => {
                                        return (
                                          i.quantidade * i.cesta.valor + acc
                                        )
                                      }, 0)
                                    )}
                                </p>
                            </div>
                        </div>`
      if (this.order.valor_delivery) {
        prtHtml += `<div class="row" style="padding: 0px; margin: 0px">
                            <div class="col" style="padding: 0px">
                                <p style="text-align: right; margin-bottom: 0px">
                                    Frete:${toDinheiro(
                                      this.order.valor_delivery
                                    )}
                                </p>
                            </div>
                        </div>
                        `
      }
      prtHtml += `<div class="row" style="margin: 0px; margin-top: 4px; padding: 0px">
                            <div class="col" style="padding: 0px">
                                <p style="text-align: right; margin-bottom: 0px">
                                    Total:
                                    <strong>${toDinheiro(
                                      this.order.total_pago
                                    )}</strong>
                                </p>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 12px; font-size: 0.9em">
                            <p style="margin-bottom: 16px">
                                <strong>Dados do Cliente</strong>
                            </p>
                        </div>
                        <div class="row" style="margin-top: 0px">
                            <div class="col" style="margin-top: 0px; padding: 12px; padding-top: 0px;">
                                <p>${this.order.cliente.nome}</p>
                            </div>
                        </div>
                        `
      if (this.order.endereco_entrega) {
        prtHtml += `<div class="row" style="margin-top: 12px; font-size: 0.9em">
                            <p style="margin-bottom: 16px">
                                <strong>Entrega</strong>
                            </p>
                        </div>
                        <div class="row" style="margin-top: 0px">
                            <div class="col" style="margin-top: 0px; padding: 12px; padding-top: 0px;">
                                <p>${this.order.endereco_entrega.address},
                                  ${this.order.endereco_entrega.number} -
                                    ${this.order.endereco_entrega.neighbor}
                                </p>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 0px">
                            <div class="col" style="margin-top: 0px; padding: 12px; padding-top: 0px;">
                                <p>${toZipCode(
                                  this.order.endereco_entrega.zip_code
                                )} -
                                  ${this.order.endereco_entrega.city}/${
          this.order.endereco_entrega.uf
        }
                                </p>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 0px">
                            <div class="col" style="margin-top: 0px; padding: 12px; padding-top: 0px;">
                                <p>${this.order.endereco_entrega.complement}
                                </p>
                            </div>
                        </div>
                        `
      }
      prtHtml += ` <div class="row" style="margin: -12px; margin-top: 5px; font-size: 0.9em;">
                            <p style="margin-bottom: 16px">
                                <strong>Nome do Estabelecimento</strong>
                            </p>
                        </div>
                        <div class="row" style="margin: -12px; margin-top: 0px;">
                            <div class="col" style="margin-top: 0px; padding: 12px; padding-top: 0px;">
                                <p>${this.order.estabelecimento.nome}</p>
                            </div>
                        </div>
                        <div class="row" style="margin: -12px; margin-top: 5px; font-size: 0.9em;">
                            <p style="margin-bottom: 16px">
                                <strong>Tipo Entrega</strong>
                            </p>
                        </div>
                        <div class="row" style="margin: -12px; margin-top: 0px;">
                            <div  class="col" style="margin-top: 0px; padding: 12px; padding-top: 0px;">
                                <p>${this.order.tipo_entrega}</p>
                            </div>
                        </div>`
      if (this.order.payments && this.order.payments.length > 0) {
        prtHtml += `

                        <div class="row" style="margin: -12px; margin-top: 5px; font-size: 0.9em;">
                            <p style="margin-bottom: 16px">
                                <strong>Forma de Pagamento</strong>
                            </p>
                        </div>
                        <div class="row" style="margin: -12px; margin-top: 0px;">
                            <div class="col" style="margin-top: 0px; padding: 12px; padding-top: 0px;">
                                <p>
                                    ${this.order.payments
                                      .map((p) =>
                                        p.payment_method === 'CREDIT_CARD'
                                          ? 'Cartão de Crédito'
                                          : p.payment_method === 'PIX'
                                          ? 'Pix'
                                          : p.payment_method ===
                                            'SODEXO_VALE_REFEICAO'
                                          ? 'Sodexo Vale Refeição'
                                          : p.payment_method ===
                                            'SODEXO_VALE_ALIMENTACAO'
                                          ? 'Sodexo Vale Alimentação'
                                          : p.payment_method === 'VOUCHER'
                                          ? 'Voucher'
                                          : p.payment_method
                                      )
                                      .join(', ')}
                                </p>
                            </div>
                        </div>`
      }
      prtHtml += `
              </div>
            </div>
        </div>
        </body>

        </html>
        `

      let doc = document.getElementById('order-to-print').contentWindow.document
      doc.open()
      doc.write(`<!DOCTYPE html>
             <html>
             <head>
                ${stylesHtml}
                <style type="text/css" media="print">
                @page
                {
                    size: auto;
                    margin: 0mm;
                }
                </style>
            </head>
             <body>
              ${prtHtml}
             </body>
             </html>`)
      doc.close()
      setTimeout(() => {
        window.frames['order-to-print'].focus()
        window.frames['order-to-print'].print()
      }, 200)
    },
    contestaCancelamento() {
      this.erros = []

      const data = {
        name: this.order.estabelecimento.nome,
        email: this.order.estabelecimento.email,
        order_number: this.order.id,
        cancellation_reason: this.order.order_cancellation.cancellation_reason,
        evidence_urls: this.order.order_cancellation.evidence_urls
      }

      this.sendGoogleAnalyticsEvent('order_cancel_contestação')

      this.$http
        .post(
          `/v1/partners/${this.order.estabelecimento.id}/orders/${this.order.uuid}/contestation`,
          data
        )
        .then((response) => {
          if (response.status === 200) {
            this.showSuccessAlert = true
          }
        })
        .catch((error) => {
          this.erros.push(error.response.data)
          this.showErrorAlert = true
        })
    },
    sendGoogleAnalyticsEvent(eventName) {
      this.$gtag.event(eventName)
    },
    shouldShowOrderEvaluationContainer(order) {
      return (
        (order.status !== 'CANCELADO' ||
          (order.status === 'CANCELADO' &&
            this.cancellationReasonsToShow.includes(
              order.order_cancellation.cancellation_reason
            ))) &&
        (order.avaliacao || order.evaluation_tag)
      )
    }
  },
  mounted() {
    this.getOrderDetails()
  }
}
</script>

<template>
  <div>
    <v-alert
      v-if="showSuccessAlert"
      class="alert success-text"
      border="left"
      color="#82E18C"
    >
      Contestação realizada com sucesso! Entraremos em contato por e-mail em até
      30 dias sobre sua solicitação.
    </v-alert>
    <v-alert
      v-if="showErrorAlert"
      class="alert error-text"
      border="left"
      color="#F2A1A1"
    >
      Houve um erro ao solicitar sua contestação. Por favor, tente novamente
      mais tarde ou
      <a
        href="https://api.whatsapp.com/send/?phone=558001230015&text&type=phone_number&app_absent=0"
        class="error-text"
        target="_blank"
        ><strong><u>contate nosso suporte</u></strong></a
      >.
    </v-alert>
    <v-container
      v-if="orderDetailsLoading"
      class="d-flex justify-center white pa-10"
      fluid
    >
      <v-progress-circular
        v-if="orderDetailsLoading"
        indeterminate
        color="primary"
      />
    </v-container>
    <v-container v-if="this.order" class="white pa-10" fluid>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <p class="text-lg-h6 ma-0">Dados do pedido</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-container
                v-if="shouldShowOrderEvaluationContainer(this.order)"
              >
                <v-row>
                  <v-container v-if="this.order.avaliacao">
                    <v-row>
                      <p class="ma-0 p-0">Avaliação:</p>
                      <v-rating
                        empty-icon="mdi-star-outline"
                        full-icon="mdi-star"
                        half-icon="mdi-star-half"
                        background-color="#FF6600"
                        color="#FF6600"
                        readonly
                        length="5"
                        size="20"
                        :value="this.order.avaliacao"
                      ></v-rating>
                    </v-row>
                  </v-container>
                  <p class="ma-0 p-0" v-if="this.order.evaluation_tag">
                    Tag: <strong>{{ this.order.evaluation_tag }}</strong>
                  </p>
                </v-row>
              </v-container>
              <p class="ma-0">
                Status:
                <strong>{{
                  this.order.status === 'WAITING_PAYMENT'
                    ? 'Aguardando Pagamento'
                    : this.order.status === 'DELIVERY' ||
                      this.order.status === 'RETIRAR'
                    ? 'Aguardando Retirada'
                    : this.order.status === 'ENTREGUE'
                    ? 'Pedido Concluído'
                    : this.order.status === 'CANCELADO'
                    ? 'Pedido Cancelado'
                    : this.order.status
                }}</strong>
              </p>
              <p
                class="ma-0"
                v-if="this.order.payments && this.order.payments.length > 0"
              >
                Forma de Pagamento:
                <strong>{{
                  this.order.payments
                    .map((p) =>
                      p.payment_method === 'CREDIT_CARD'
                        ? 'Cartão de Crédito'
                        : p.payment_method === 'PIX'
                        ? 'Pix'
                        : p.payment_method === 'SODEXO_VALE_REFEICAO'
                        ? 'Sodexo Vale Refeição'
                        : p.payment_method === 'SODEXO_VALE_ALIMENTACAO'
                        ? 'Sodexo Vale Alimentação'
                        : p.payment_method === 'VOUCHER'
                        ? 'Voucher'
                        : p.payment_method
                    )
                    .join(', ')
                }}</strong>
              </p>
              <p class="ma-0 p-0"></p>
              <p class="ma-0 p-0">
                Tipo da Entrega: <strong>{{ this.order.tipo_entrega }}</strong>
              </p>
              <p class="ma-0 p-0">
                Telefone do Cliente:
                <strong>{{ toTelefone(this.order.cliente.phone) }}</strong>
              </p>
              <p class="ma-0 p-0" v-if="this.order.external_id">
                Pedido do Parceiro:
                <strong>{{ this.order.external_id }}</strong>
              </p>
            </v-col>
          </v-row>
          <div id="order-invoice-print">
            <v-container
              style="
                border: 1px dashed;
                padding: 12px;
                max-width: 260px;
                font-size: 12px;
              "
            >
              <v-col style="padding: 12px">
                <v-row style="margin: 0px; padding: 0px">
                  <v-col style="padding: 0px">
                    <p style="font-weight: bold; font-size: 1.1em; margin: 0px">
                      #{{ this.order.id }}
                    </p>
                    <p
                      style="font-weight: bold; font-size: 0.9em; margin: 0px"
                      v-if="this.order.external_id"
                    >
                      {{ this.order.external_id }}
                    </p>
                  </v-col>
                  <v-col style="padding: 0px">
                    <p
                      style="
                        margin: 0px;
                        font-weight: bold;
                        font-size: 1.1em;
                        text-align: right;
                      "
                    >
                      <strong>
                        {{ formatLocaleDate(this.order.created_at) }}
                      </strong>
                    </p>
                  </v-col>
                </v-row>
                <v-row style="padding: 0px; margin: 0px; margin-top: 16px">
                  <v-col style="padding: 0px; margin: 0px">
                    <p
                      style="
                        font-weight: bold;
                        font-size: 1.1em;
                        text-align: center;
                      "
                    >
                      PEDIDO
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  v-for="item in this.order.itens"
                  :key="item.cesta.id"
                  class="mt-0"
                  style="padding: 12px; margin-top: 0px; font-size: 0.9em"
                >
                  <v-col
                    style="padding: 0px; width: 100%; flex-grow: 1 !important"
                    ><strong style="font-size: 1.2em">{{
                      item.quantidade
                    }}</strong>
                    x
                    {{
                      item.cesta.category && item.cesta.category !== ''
                        ? item.cesta.category + ' - '
                        : ''
                    }}
                    {{ item.cesta.tipo }}
                    {{ item.tag ? ' - ' : '' }}
                    <strong v-if="item.tag != null">#{{ item.tag }}</strong>
                    <br />
                    <sup class="text-decoration-line-through">
                      {{ toDinheiro(item.cesta.valor_referencia) }}
                    </sup>
                    {{ toDinheiro(item.cesta.valor) }}
                  </v-col>
                  <v-col
                    style="
                      padding: 0px;
                      flex-shrink: 1 !important;
                      flex-basis: 0;
                      flex-grow: 0 !important;
                    "
                  >
                    <p style="margin-bottom: 0px; text-align: right">
                      {{ toDinheiro(item.cesta.valor) }}
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  v-if="this.order.itens"
                  style="padding: 0px; margin: 0px; margin-top: 12px"
                >
                  <v-col style="padding: 0px">
                    <p style="text-align: right; margin-bottom: 0px">
                      Sub-total:
                      {{
                        toDinheiro(
                          this.order.itens.reduce((acc, i) => {
                            return i.quantidade * i.cesta.valor + acc
                          }, 0)
                        )
                      }}
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  v-if="this.order.valor_delivery"
                  style="padding: 0px; margin: 0px"
                >
                  <v-col style="padding: 0px">
                    <p style="text-align: right; margin-bottom: 0px">
                      Frete: {{ toDinheiro(this.order.valor_delivery) }}
                    </p>
                  </v-col>
                </v-row>
                <v-row style="margin: 0px; margin-top: 4px; padding: 0px">
                  <v-col style="padding: 0px">
                    <p style="text-align: right; margin-bottom: 0px">
                      Total:
                      <strong>{{ toDinheiro(this.order.total_pago) }}</strong>
                    </p>
                  </v-col>
                </v-row>
                <v-row style="margin-top: 12px; font-size: 0.9em">
                  <p style="margin-bottom: 16px">
                    <strong>Dados do Cliente</strong>
                  </p>
                </v-row>
                <v-row style="margin-top: 0px">
                  <v-col class="body">
                    <p>
                      {{ this.order.cliente.nome }}
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  style="margin-top: 12px; font-size: 0.9em"
                  v-if="this.order.endereco_entrega"
                >
                  <p style="margin-bottom: 16px">
                    <strong>Entrega</strong>
                  </p>
                </v-row>
                <v-row
                  style="margin-top: 0px"
                  v-if="this.order.endereco_entrega"
                >
                  <v-col class="body">
                    <p>
                      {{ this.order.endereco_entrega.address }} ,
                      {{ this.order.endereco_entrega.number }} -
                      {{ this.order.endereco_entrega.neighbor }}
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  style="margin-top: 0px"
                  v-if="this.order.endereco_entrega"
                >
                  <v-col class="body">
                    <p>
                      {{ toZipCode(this.order.endereco_entrega.zip_code) }} -
                      {{ this.order.endereco_entrega.city }}/{{
                        this.order.endereco_entrega.uf
                      }}
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  style="margin-top: 0px"
                  v-if="this.order.endereco_entrega"
                >
                  <v-col class="body">
                    <p>
                      {{ this.order.endereco_entrega.complement }}
                    </p>
                  </v-col>
                </v-row>
                <v-row class="tittle" v-if="this.order.estabelecimento.name">
                  <p style="margin-bottom: 16px">
                    <strong>Nome do Estabelecimento</strong>
                  </p>
                </v-row>
                <v-row
                  class="margin-body"
                  v-if="this.order.estabelecimento.nome"
                >
                  <v-col class="body">
                    <p>
                      {{ this.order.estabelecimento.nome }}
                    </p>
                  </v-col>
                </v-row>
                <v-row class="tittle" v-if="this.order.tipo_entrega">
                  <p style="margin-bottom: 16px">
                    <strong>Tipo Entrega</strong>
                  </p>
                </v-row>
                <v-row class="margin-body" v-if="this.order.tipo_entrega">
                  <v-col class="body">
                    <p>
                      {{ this.order.tipo_entrega }}
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  class="tittle"
                  v-if="this.order.payments && this.order.payments.length > 0"
                >
                  <p style="margin-bottom: 16px">
                    <strong>Forma de Pagamento</strong>
                  </p>
                </v-row>
                <v-row
                  class="margin-body"
                  v-if="this.order.payments && this.order.payments.length > 0"
                >
                  <v-col class="body">
                    <p>
                      {{
                        this.order.payments
                          .map((p) =>
                            p.payment_method === 'CREDIT_CARD'
                              ? 'Cartão de Crédito'
                              : p.payment_method === 'PIX'
                              ? 'Pix'
                              : p.payment_method === 'SODEXO_VALE_REFEICAO'
                              ? 'Sodexo Vale Refeição'
                              : p.payment_method === 'SODEXO_VALE_ALIMENTACAO'
                              ? 'Sodexo Vale Alimentação'
                              : p.payment_method === 'VOUCHER'
                              ? 'Voucher'
                              : p.payment_method
                          )
                          .join(', ')
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-container>
          </div>
          <v-row class="mt-2">
            <v-col class="text-center">
              <v-btn color="primary" elevation="3" large @click="print"
                >Imprimir
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="
            this.order.status === 'CANCELADO' &&
            this.order.order_cancellation.cancellation_reason_description &&
            this.order.order_cancellation.cancellation_reason_description !== ''
          "
        >
          <v-row>
            <v-col>
              <p class="text-lg-h6 ma-0">Informações do cancelamento</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p
                class="ma-0"
                v-if="
                  this.order.order_cancellation.cancellation_reason_description
                "
              >
                Motivo:
                <strong>{{
                  this.order.order_cancellation.cancellation_reason_description
                }}</strong>
              </p>
              <v-row
                class="ma-0 pa-0"
                v-if="
                  this.order.order_cancellation.evidence_urls.length > 0 &&
                  this.order.order_cancellation.cancellation_reason ===
                    'ITEMS_EXPIRED'
                "
              >
                <p style="padding-top: 6px">Imagens:</p>
                <p
                  v-for="(evidence, index) in this.order.order_cancellation
                    .evidence_urls"
                  :key="evidence"
                >
                  <v-btn icon>
                    {{ index + 1 }}
                    <v-icon outlined center dark @click="openPopup(evidence)">
                      mdi-image-outline
                    </v-icon>
                  </v-btn>
                </p>
              </v-row>
              <div
                :class="{
                  'remove-margin':
                    this.order.order_cancellation.evidence_urls.length > 0 &&
                    this.order.order_cancellation.cancellation_reason ===
                      'ITEMS_EXPIRED'
                }"
              >
                <v-row>
                  <v-col style="padding-top: 23px">
                    <p>
                      <a
                        href="https://link.foodtosave.com.br/politicadecancelamento"
                        class="help-link"
                        target="_blank"
                      >
                        Duvidas sobre cancelamento ?</a
                      >
                    </p>
                  </v-col>
                  <v-col
                    style="padding-top: 19px"
                    v-if="
                      this.order.order_cancellation.cancellation_reason ===
                      'ITEMS_EXPIRED'
                    "
                  >
                    <div
                      v-if="this.order.order_cancellation.contested_at"
                      class="contested-text"
                    >
                      Cancelamento Contestado
                    </div>
                    <div v-else>
                      <v-btn
                        color="#454845"
                        dark
                        small
                        class="cancel-btn"
                        @click="contestaCancelamento"
                      >
                        <span class="cancel-btn-text"
                          >Contestar Cancelamento</span
                        >
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="
            this.order &&
            this.order.tipo_entrega === 'DELIVERY' &&
            this.deliveryDetails
          "
        >
          <v-progress-circular
            v-if="deliveryDetailsLoading"
            indeterminate
            color="primary"
          />
          <v-row>
            <v-col>
              <p class="text-lg-h6 ma-0">Dados da Retirada</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="ma-0">
                Status:
                <strong>{{
                  this.deliveryDetails.status === 'INVALID'
                    ? 'Entrega Inválida'
                    : this.deliveryDetails.status === 'PLANNED'
                    ? 'Entrega em planejamento'
                    : this.deliveryDetails.status === 'ASSIGNING'
                    ? 'Procurando entregador'
                    : this.deliveryDetails.status === 'ASSIGNED'
                    ? 'Entregador atribuído'
                    : this.deliveryDetails.status === 'DEPARTED'
                    ? 'Entregador a caminho do estabelecimento'
                    : this.deliveryDetails.status === 'AT_PICKUP'
                    ? 'Entregador chegou no estabelecimento'
                    : this.deliveryDetails.status === 'PICKED_UP'
                    ? 'Entrega retirada'
                    : this.deliveryDetails.status === 'AT_DELIVERY'
                    ? 'Entregador chegou no cliente'
                    : this.deliveryDetails.status === 'FINISHED'
                    ? 'Entrega finalizada'
                    : this.deliveryDetails.status === 'DELAYED'
                    ? 'Entrega atrasada'
                    : this.deliveryDetails.status === 'FAILED'
                    ? 'Entrega falhou'
                    : this.deliveryDetails.status === 'RETURNING'
                    ? 'Entregador retornando ao local de retirada'
                    : this.deliveryDetails.status === 'RETURN_FINISHED'
                    ? 'Entregador retornou ao local de retirada'
                    : this.deliveryDetails.status === 'REJECTED' ||
                      this.deliveryDetails.status === 'CANCELED' ||
                      this.deliveryDetails.status === 'DELETED'
                    ? 'Entrega Cancelada'
                    : this.order.status
                }}</strong>
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p><strong>Dados do Entregador</strong></p>
            </v-col>
          </v-row>
          <v-row
            class="mt-0"
            v-if="!this.deliveryDetails || !this.deliveryDetails.courier_info"
          >
            <v-col class="mt-0 pt-0">
              <p>Dados do entregador não disponíveis.</p>
            </v-col>
          </v-row>
          <v-row class="mt-0 mb-3 pl-2" v-else>
            <v-col class="mt-0 pt-0 d-flex flex-grow-0 flex-shrink-1">
              <v-avatar size="60">
                <img
                  v-if="
                    this.deliveryDetails &&
                    this.deliveryDetails.courier_info.photo_url
                  "
                  alt="Avatar"
                  style="object-fit: cover"
                  :src="this.deliveryDetails.courier_info.photo_url"
                />
              </v-avatar>
            </v-col>
            <v-col
              class="mt-0 pt-0 d-flex flex-column flex-grow-1 align-self-center"
            >
              <p class="ma-0">
                <strong>{{ this.deliveryDetails.courier_info.name }}</strong>
              </p>
              <p class="ma-0">
                {{ toTelefone(this.deliveryDetails.courier_info.phone) }}
              </p>
            </v-col>
          </v-row>
          <map-view
            v-if="this.markers"
            :markers="this.markers"
            style="border: 1px dashed"
          />
        </v-col>
      </v-row>
    </v-container>
    <iframe
      id="order-to-print"
      name="order-to-print"
      style="display: none"
    ></iframe>
  </div>
</template>

<style scoped>
.tittle {
  margin: -12px;
  margin-top: 5px;
  font-size: 0.9em;
}

.body {
  margin-top: 0px;
  padding: 12px;
  padding-top: 0px;
}

.body p {
  margin: 0px;
  font-size: 0.8em;
}

.margin-body {
  margin: -12px;
  margin-top: 0px;
}

.remove-margin {
  margin-top: -35px;
}

.help-link {
  color: #454845;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 10.7px;
  margin: 0;
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 1.5px solid #454845;
  position: relative;
  top: -5px;
}

.cancel-btn {
  width: 160px;
  height: 25px;
  right: 15px;
  border-radius: 5px;
}

.cancel-btn-text {
  font-size: 10px;
  color: #fff;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none;
}

.alert {
  position: fixed;
  top: 6%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 60%;
  min-height: 7%;
  max-width: 800px;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-text {
  color: #006a0b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.error-text {
  color: #6a0000;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cancel-btn {
  width: 160px;
  height: 25px;
  right: 15px;
  border-radius: 5px;
}

.contested-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none;
  font-size: 10px;
  width: 160px;
  height: 25px;
  right: 15px;
  border-radius: 5px;
  background-color: #ffecec;
  border: 1px solid #ff0000;
  color: #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
